import React from "react";

import { CircularProgress } from "@mui/material";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SnackbarManager from "./shared/SnackbarManager";

const Me = React.lazy(() => import("./projects/me"));
const S3X = React.lazy(() => import("./projects/s3x"));
const WebRtc = React.lazy(() => import("./projects/webRTC"));
const TTT = React.lazy(() => import("./projects/ttt"));
const G = React.lazy(() => import("./projects/gallery"));
const Beat = React.lazy(() => import("./projects/beat"));
const Heat = React.lazy(() => import("./projects/heat"));
const Plane = React.lazy(() => import("./projects/plane_school_project"));

function MainRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/webrtc" element={<WebRtc />} />
        <Route path="/ttt" element={<TTT />} />
        <Route path="/gallery" element={<G />} />
        <Route path="/heat" element={<Heat />} />
        <Route path="/plane" element={<Plane />} />
        <Route path="/beat" element={<Beat />} />
        <Route path="/s3x/*" element={<S3X />} />
        <Route>
          <Route path="*" element={<Me />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default function App() {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <MainRoutes />
      <SnackbarManager />
    </React.Suspense>
  );
}
