import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { v4 } from 'uuid';

export default class SnackbarManager extends React.Component {
  static show(message, action) {
    const id = v4();

    const bar = (
      <Snackbar
        id={id}
        key={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        action={action}
        open
        autoHideDuration={2000}
        onExited={() => {
          const { bars } = this.state;
          this.setState({
            bars: [...bars.slice(1)],
          });
        }}
        onClose={(e, reason) => {
          if (reason === 'clickaway') {
            return;
          }

          const { bars } = this.state;
          const current = React.cloneElement(bars[0], { open: false });
          this.setState({
            bars: [current, ...bars.slice(1)],
          });
        }}
        message={message}
      />
    );
    const { bars } = this.state;
    this.setState({
      bars: [...bars, bar],
    });
  }

  static hide() {
    const { bars } = this.state;
    this.setState({
      bars: bars.slice(1),
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      bars: [],
    };

    SnackbarManager.show = SnackbarManager.show.bind(this);
    SnackbarManager.hide = SnackbarManager.hide.bind(this);
  }

  render() {
    const { bars } = this.state;
    return <>{bars.slice(0, 1)}</>;
  }
}
